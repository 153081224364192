<template>
    <VisitorPage :vertical-center="true">
        <section id="login">
            <div class="container">
                <p class="typography--body-bold">{{ $t('Welcome') }}</p>
                <h1 class="typography--h1-bold mt-4 mb-12">{{ $t('HygieneControl') }}</h1>
                <div class="" v-html="$t('Login.Intro text')" />


                <form @submit.prevent="onSubmit">
                    <b-field custom-class="is-small" :label="$t('Email address')">
                        <b-input v-model="data.email" />
                    </b-field>
                    <b-field custom-class="is-small" :label="$t('Password')">
                        <b-input v-model="data.password" password-reveal type="password" />
                    </b-field>
                    <div style="text-align: right">
                        <router-link :to="{ name:'forgot-password' }">
                            {{ $t('Reset your password here') }}
                        </router-link>
                    </div>

                    <b-message v-if="error" type="is-danger" has-icon>
                        {{ error }}
                    </b-message>

                    <div class="buttons mt-16">
                        <b-button native-type="submit" type="is-primary" class="mr-8">
                            {{ $t('Sign in') }}
                        </b-button>
                    </div>
                </form>
            </div>
        </section>
    </VisitorPage>
</template>

<script>
    import { mapActions, mapGetters, mapState } from 'vuex'
    import VisitorPage from '@/components/VisitorPage'

    export default {
        name: 'Login',
        components: { VisitorPage },
        i18nPrefix: 'Login',
        data() {
            return {
                data: {
                    email: null,
                    password: null
                }
            }
        },
        computed: {
            ...mapGetters('auth', [ 'isLoggedIn' ]),
            ...mapState('auth', [ 'error' ])
        },
        mounted() {
            this.$store.commit('auth/setError', null)
        },
        methods: {
            ...mapActions('auth', [ 'login' ]),
            async onSubmit() {
                await this.login(this.data)
                if (this.isLoggedIn)
                    await this.$router.push({ name: 'home' })
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "~@/assets/vars";

</style>
